import { useState, useEffect } from "react";

import './Footer.css';

import { SvgFacebook, SvgInstagram, SvgTwitter } from "components/SvgFile";

import { GetListPag, RegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function Page_Footer(props){
    const [ idioma, setIdioma ]         = useState(GetListPag('idioma'));
    const [ namePage, setNamePage ]     = useState(GetDataPage('name_menu'));

    const [ translate, setTranslate ] = useState([
        {
            "en": "Let Us Help You Captivate Your Audience",
            "es": "Permítanos ayudarlo a cautivar a su audiencia",
            "pt_br": "Deixe-nos ajudá-lo a cativar seu público"
        },
        {
            "en": "Get a Quote",
            "es": "Consigue una cotización",
            "pt_br": "Faça uma cotação"
        },
        {
            "en": "Privacy Policy",
            "es": "Política de privacidad",
            "pt_br": "Política de Privacidade"
        },
        {
            "en": "All rights reserved",
            "es": "Todos los derechos reservados",
            "pt_br": "Todos os direitos reservados"
        }
    ]);
    
    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage('name_menu', setNamePage);
    }, []);

    return(
        <div className="Page_Footer">
            
            <div className="div_color_line">
                <div className="line_top">
                    <div className="line_ft_1" />
                    <div className="line_ft_2" />
                    <div className="line_ft_3" />
                    <div className="line_ft_4" />
                </div>
                <div className="line_bottom">
                    <div className="line_ft_1" />
                    <div className="line_ft_2" />
                    <div className="line_ft_3" />
                    <div className="line_ft_4" />
                </div>
            </div>
            
            <div className="div_img">
                <a href="/#">
                    <img src="./assets/01.png" className="img" />
                </a>
            </div>

            <div className="div_data_footer contents">
                <div className="show_data">
                    <div className="div_btn">
                        <div className="title">{ translate[0][idioma] }</div>
                        <a href="/#contact" onClick={ ()=>{ props.AltPag('contact'); } } target="_blank" className="btn">
                            { translate[1][idioma] }
                        </a>
                    </div>
                    <div className="div_name_page">
                        <div className="title_click"></div>
                        {
                            namePage.map((key, index)=>{
                                return(
                                    <div className="name_page" key={ index }>
                                        <a href={ "/#" + key.href } data-text={ key.title[idioma] } onClick={ ()=>{ props.AltPag(key.href); } }>
                                            { key.title[idioma] }
                                        </a>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="div_data_rede_social">
                    <div className="origin">
                       { translate[2][idioma] }<br /> CNPJ 20.817.641/0001-40
                    </div>
                    <div className="origin_center">
                        Copyright ©ChromaGarden<br /> { translate[3][idioma] }
                    </div>
                    <div className="show_rede_social">
                        <a href="#" className="rede_social">
                            <SvgInstagram className="icons" color="#9DAFBD" />
                        </a>
                        <a href="#" className="rede_social">
                            <SvgTwitter className="icons" color="#9DAFBD" />
                        </a>
                        <a href="#" className="rede_social">
                            <SvgFacebook className="icons" color="#9DAFBD" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}