import { useState, useEffect } from "react";

import './Portfolio.css';

import { GetListPag, RegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function ModelPage_Portfolio(props){

    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));
    const [ showData, setShowData ] = useState(InitialData());

    const [ listBtn, setListBtn ] = useState(ShowDataIdioma());
    
    const [ nameBtn, setNameBtn ]       = useState([
        {
            "en": "PORTFOLIO",
            "es": "PORTAFOLIO",
            "pt_br": "PORTFÓLIO"
        },
        {
            "en": "MOST RECENT WORKS",
            "es": "OBRAS MAS RECIENTES",
            "pt_br": "TRABALHOS MAIS RECENTES"
        },
        {
            "en": "SEE ALL PORTFOLIO",
            "es": "VER TODO EL PORTAFOLIO",
            "pt_br": "VEJA TODO O PORTFÓLIO"
        }
    ]);

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage(props.origin, setDataPage);
    }, []);

    function InitialData(){
        if(props.origin == 'portfolio'){
            const newData = dataPage;
            return newData;
        }else {
            const newData = dataPage.find(item => item.id == props.id_page);
            return newData;
        }
    }

    function ShowDataIdioma(){
        const dataPortfolio = [];
        if(props.origin == 'portfolio'){
            return showData.portfolio;
        }else {
            const newData = showData.list_btn.filter(item => item.idioma == idioma);
            switch (newData.length) {
                case 1:
                        newData.map((key, index)=>{
                            dataPortfolio.push(key);
                        })
                        newData.map((key, index)=>{
                            dataPortfolio.push(key);
                        })
                        newData.map((key, index)=>{
                            dataPortfolio.push(key);
                        })
                        newData.map((key, index)=>{
                            dataPortfolio.push(key);
                        })
                        newData.map((key, index)=>{
                            dataPortfolio.push(key);
                        })
                    break;
                case 2:
                        newData.map((key, index)=>{
                            dataPortfolio.push(key);
                        })
                        newData.map((key, index)=>{
                            dataPortfolio.push(key);
                        })
                        newData.map((key, index)=>{
                            dataPortfolio.push(key);
                        })
                    break;
                case 3:
                        newData.map((key, index)=>{
                            dataPortfolio.push(key);
                        })
                        newData.map((key, index)=>{
                            dataPortfolio.push(key);
                        })
                    break;
                default:
                        newData.map((key, index)=>{
                            dataPortfolio.push(key);
                        })
                    break;
            }
        }
        return dataPortfolio;
    }

    return(
        <div className="ModelPage_Portfolio">
            <div className="div_title">
                <div className="title_page">{ nameBtn[0][idioma] }</div>
                <div className="subtitle_page">{ nameBtn[1][idioma] }</div>
            </div>
            <div className="list_portfolio">
                {
                    listBtn.map((key, index)=>{
                        return(
                            <a href={ "/#preview#" + key.id_portfolio } onClick={ ()=>{ props.AltPag('preview') } } className="div_show_portfolio" key={ index }>
                                <div className="img_">
                                    <img alt={ key.title } src={ key.thumbnail } className="show_img" />
                                </div>
                                <div className="show_name">
                                    <div className="name">
                                        { key.client }
                                    </div>
                                    <div className="name_detalis">
                                        { key.title }
                                    </div>
                                </div>
                            </a>
                        )
                    })
                }
            </div>
            {
                props.origin == 'portfolio' ? null :
                <div className="div_btn">
                    <a className="name_btn" href={ "/#works" } onClick={ ()=>{ props.AltPag('works') } }>
                        {
                            nameBtn[2][idioma]
                        }
                    </a>
                </div>
            }
        </div>
    )

}