import { useState, useEffect } from "react";

import './Home.css';

import { GetListPag, RegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgBehance, SvgInstagram, SvgYoutube } from "components/SvgFile";

export default function ModelPage_Home(props){

    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));
    const [ showData, setShowData ] = useState(InitialData());

    const [ textFixed, setTextFixed ] = useState({
        "en": "let's talk",
        "es": "hablemos",
        "pt_br": "vamos conversar"
    });

    const opts = { 
        loop: true,
        playerVars: {
            autoplay: true,
            controls: false,
            rel: false,
            showinfo: false,
            mute: true,
            loop: true
        } 
    };

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage(props.origin, setDataPage);

        setTimeout(() => {
            if(document.querySelector('.show_rede_social')){
                document.querySelector('.show_rede_social').style.zIndex = '11';
            }
        }, 4500);
    }, []);

    function InitialData(){
        const newData = dataPage.find(item => item.id == props.id_page);
        return newData;
    }
    
    function ShowVideo(type, video){
        switch (type) {
            case 'Vimeo':
                return(
                    <div className="video_background">
                        <div className="video_foreground">
                            <iframe src={ "https://player.vimeo.com/video/" + video + "?&amp;autoplay=1&amp;loop=1&amp;playlist=" + video + "&amp;rel=0&amp;controls=0&amp;mute=1" } frameBorder="0" allowFullScreen></iframe>
                        </div>
                    </div>
                )

            case 'Youtube':
                return(
                    <div className="video_background">
                        <div className="video_foreground">
                            <iframe src={ "https://www.youtube.com/embed/" + video + "?&amp;autoplay=1&amp;loop=1&amp;playlist=" + video + "&amp;rel=0&amp;controls=0&amp;mute=1" } frameBorder="0" allowFullScreen></iframe>
                        </div>
                    </div>
                )
        }
    }

    return(
        <div className="ModelPage_Home">
            <div className="div_text">
                <div className="show_title" dangerouslySetInnerHTML={ { __html: showData.title[idioma] ? showData.title[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                <div className="show_text" dangerouslySetInnerHTML={ { __html: showData.text[idioma] ? showData.text[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
            </div>
                
            <div className="div_video_or_img">
                <div className="dotted" />
                <div className="show_rede_social">
                    <div className="list_data_rede_social">
                        <a href="/#contact" className="name_click" onClick={ ()=>{ props.AltPag('contact'); } }>
                            { textFixed[idioma] }
                        </a>
                        <div className="div_rede_social">
                            <div className="div_icons_social">
                                <a href="https://www.youtube.com/user/chromagarden" target="_blank">
                                    <SvgYoutube className="icons_rede" color="#ffffff" />
                                </a>
                            </div>
                            <div className="div_icons_social">
                                <a href="https://www.instagram.com/chromagarden/" target="_blank">
                                    <SvgInstagram className="icons_rede" color="#ffffff" />
                                </a>
                            </div>
                            <div className="div_icons_social">
                                <a href="https://www.behance.net/chromagarden" target="_blank">
                                    <SvgBehance className="icons_rede" color="#ffffff" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    showData.type[idioma] == "video" ? 
                        ShowVideo(showData.type_video[idioma], showData.file[idioma]) : 
                        <img alt="img" src={ "" } className="show_full" />
                }
            </div>
        </div>
    )
}