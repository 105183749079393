
export function SvgLogoTipo(props){
    return(
        <svg viewBox="0 0 112 73" className={ props.className } fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M85.0867 36.815L93.3624 48.3305L92.3889 53.6828L46.6855 58.0611L50.7362 35.5183L109.731 29.68L112 16.8678L40.0411 23.8416L36.8005 41.032L15.5691 43.1394L19.9443 18.6505L65.811 14.2723L65.3253 17.1925L78.4531 16.0571L81.3605 0L9.07492 6.97378L0 57.4138L34.5203 54.0076L31.1165 72.3333L103.239 65.1983L108.749 34.5464L85.0867 36.815Z" fill="url(#paint0_linear_201_1405)"/>
            <defs>
                <linearGradient id="paint0_linear_201_1405" x1="-0.112" y1="36.1667" x2="112" y2="36.1667" gradientUnits="userSpaceOnUse">
                    <stop stopColor={ props.color }/>
                    <stop offset="1" stopColor={ props.color }/>
                </linearGradient>
            </defs>
        </svg>
    )
}

export function SvgFacebook(props){
    return(
        <svg viewBox="0 0 26.477 26.477" className={ props.className } fill="none">
            <circle cx="13.238" cy="13.238" r="13.238" transform="translate(0)" fill={ props.color } />
            <path id="Shape" d="M7.643,8.621H5.281v8.654H1.7V8.621H0V5.579H1.7V3.611A3.356,3.356,0,0,1,5.313,0L7.964.011V2.963H6.041a.729.729,0,0,0-.759.829v1.79H7.956Z" transform="translate(8.923 5.136)" fill="#1b252c"/>
        </svg>
    )
}

export function SvgInstagram(props){
    return(
        <svg viewBox="0 0 26.594 26.594" className={ props.className } fill="none">
            <path id="Shape" d="M10.222,12.516H2.294A2.3,2.3,0,0,1,0,10.222V2.294A2.3,2.3,0,0,1,2.294,0h7.927a2.3,2.3,0,0,1,2.294,2.294v7.927A2.3,2.3,0,0,1,10.222,12.516ZM6.258,2.137a4.12,4.12,0,1,0,4.12,4.121A4.125,4.125,0,0,0,6.258,2.137Zm4.253-1.1a.974.974,0,1,0,.974.974A.976.976,0,0,0,10.511,1.04Z" transform="translate(7.04 7.076)" fill={ props.color }/>
            <path id="Shape-2" d="M2.379,0A2.379,2.379,0,1,0,4.758,2.379,2.382,2.382,0,0,0,2.379,0Z" transform="translate(10.918 10.954)" fill={ props.color }/>
            <path id="Shape-3" d="M13.3,26.594a13.3,13.3,0,1,1,13.3-13.3A13.312,13.312,0,0,1,13.3,26.594ZM9.335,5.334A4.04,4.04,0,0,0,5.3,9.37V17.3a4.04,4.04,0,0,0,4.036,4.036h7.926A4.04,4.04,0,0,0,21.3,17.3V9.37a4.04,4.04,0,0,0-4.036-4.036Z" transform="translate(0)" fill={ props.color }/>
        </svg>
    )
}

export function SvgTwitter(props){
    return(
        <svg viewBox="0 0 26.477 26.477" className={ props.className } fill="none">
            <circle id="Oval" cx="13.238" cy="13.238" r="13.238" transform="translate(0)" fill={ props.color }/>
            <path id="Shape" d="M15.424,1.484a6.313,6.313,0,0,1-1.818.5A3.173,3.173,0,0,0,15,.231,6.335,6.335,0,0,1,12.988,1,3.167,3.167,0,0,0,7.6,3.886,8.983,8.983,0,0,1,1.074.579,3.168,3.168,0,0,0,2.053,4.8a3.142,3.142,0,0,1-1.433-.4c0,.013,0,.027,0,.04a3.166,3.166,0,0,0,2.539,3.1A3.177,3.177,0,0,1,1.729,7.6,3.168,3.168,0,0,0,4.685,9.8a6.349,6.349,0,0,1-3.93,1.355A6.372,6.372,0,0,1,0,11.113a8.951,8.951,0,0,0,4.85,1.422,8.942,8.942,0,0,0,9-9c0-.137,0-.274-.009-.409a6.417,6.417,0,0,0,1.579-1.638Z" transform="translate(6.667 8)" fill="#1b252c"/>
        </svg>
    )
}

export function SvgLinkedin(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M19 3A2 2 0 0 1 21 5V19A2 2 0 0 1 19 21H5A2 2 0 0 1 3 19V5A2 2 0 0 1 5 3H19M18.5 18.5V13.2A3.26 3.26 0 0 0 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17A1.4 1.4 0 0 1 15.71 13.57V18.5H18.5M6.88 8.56A1.68 1.68 0 0 0 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19A1.69 1.69 0 0 0 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56M8.27 18.5V10.13H5.5V18.5H8.27Z"/>
        </svg>
    )
}

export function SvgVimeo(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M22,7.42C21.91,9.37 20.55,12.04 17.92,15.44C15.2,19 12.9,20.75 11,20.75C9.85,20.75 8.86,19.67 8.05,17.5C7.5,15.54 7,13.56 6.44,11.58C5.84,9.42 5.2,8.34 4.5,8.34C4.36,8.34 3.84,8.66 2.94,9.29L2,8.07C3,7.2 3.96,6.33 4.92,5.46C6.24,4.32 7.23,3.72 7.88,3.66C9.44,3.5 10.4,4.58 10.76,6.86C11.15,9.33 11.42,10.86 11.57,11.46C12,13.5 12.5,14.5 13.05,14.5C13.47,14.5 14.1,13.86 14.94,12.53C15.78,11.21 16.23,10.2 16.29,9.5C16.41,8.36 15.96,7.79 14.94,7.79C14.46,7.79 13.97,7.9 13.46,8.12C14.44,4.89 16.32,3.32 19.09,3.41C21.15,3.47 22.12,4.81 22,7.42Z" />
        </svg>
    )
}

export function SvgYoutube(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M10,15L15.19,12L10,9V15M21.56,7.17C21.69,7.64 21.78,8.27 21.84,9.07C21.91,9.87 21.94,10.56 21.94,11.16L22,12C22,14.19 21.84,15.8 21.56,16.83C21.31,17.73 20.73,18.31 19.83,18.56C19.36,18.69 18.5,18.78 17.18,18.84C15.88,18.91 14.69,18.94 13.59,18.94L12,19C7.81,19 5.2,18.84 4.17,18.56C3.27,18.31 2.69,17.73 2.44,16.83C2.31,16.36 2.22,15.73 2.16,14.93C2.09,14.13 2.06,13.44 2.06,12.84L2,12C2,9.81 2.16,8.2 2.44,7.17C2.69,6.27 3.27,5.69 4.17,5.44C4.64,5.31 5.5,5.22 6.82,5.16C8.12,5.09 9.31,5.06 10.41,5.06L12,5C16.19,5 18.8,5.16 19.83,5.44C20.73,5.69 21.31,6.27 21.56,7.17Z" />
        </svg>
    )
}

export function SvgBehance(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M22 7h-7v-2h7v2zm1.726 10c-.442 1.297-2.029 3-5.101 3-3.074 0-5.564-1.729-5.564-5.675 0-3.91 2.325-5.92 5.466-5.92 3.082 0 4.964 1.782 5.375 4.426.078.506.109 1.188.095 2.14h-8.027c.13 3.211 3.483 3.312 4.588 2.029h3.168zm-7.686-4h4.965c-.105-1.547-1.136-2.219-2.477-2.219-1.466 0-2.277.768-2.488 2.219zm-9.574 6.988h-6.466v-14.967h6.953c5.476.081 5.58 5.444 2.72 6.906 3.461 1.26 3.577 8.061-3.207 8.061zm-3.466-8.988h3.584c2.508 0 2.906-3-.312-3h-3.272v3zm3.391 3h-3.391v3.016h3.341c3.055 0 2.868-3.016.05-3.016z"/>
        </svg>
    )
}

export function SvgSetaRight(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path d="M9 18L15 12L9 6" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function SvgSetaDown(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path d="M6 9L12 15L18 9" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}