import { useState, useEffect } from "react";

import './OurServices.css';

import { GetListPag, RegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { SvgSetaDown, SvgSetaRight } from "components/SvgFile";

export default function ModelPage_OurServices(props){

    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));
    const [ showData, setShowData ] = useState(InitialData());

    const [ opt, setOpt ]         = useState(1);
    const [ listBtn, setListBtn ] = useState(ShowListBtn());

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage(props.origin, setDataPage);
    }, []);

    function ShowBtn(value){
        setOpt(value);
    }

    function ShowListBtn(){
        const newData = showData.list_btn.filter(item => item.idioma == idioma);
        return newData;
    }

    function InitialData(){
        const newData = dataPage.find(item => item.id == props.id_page);
        return newData;
    }

    return(
        <div className="ModelPage_OurServices">
            <div className="div_text">
                <div className="show_text">
                    <div className="subtitle">{ showData.subtitle[idioma] }</div>
                    <div className="title" dangerouslySetInnerHTML={ { __html: showData.title[idioma] ? showData.title[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                    <div className="text" dangerouslySetInnerHTML={ { __html: showData.text[idioma] ? showData.text[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                </div>
            </div>
            <div className="list_btn">
                {
                    listBtn.map((key, index)=>{
                        return(
                            <div className="div_btn" key={ index }>
                                <div className="show_btn" onClick={ ()=>{ ShowBtn(key.opt) } }>
                                    <div className="name_btn">
                                        { key.name }
                                    </div>
                                    <div className="arrow_btn">
                                        {
                                            key.opt == opt ? 
                                            <SvgSetaRight className="icons arrow_down" color="#ffffff" /> :
                                            <SvgSetaRight className="icons" color="#ffffff" />
                                        }
                                    </div>
                                </div>
                                <div className={ key.opt == opt ? "show_text text_open" : "show_text" } dangerouslySetInnerHTML={ { __html: key.opt == opt ? key.text ? key.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" : "" } } />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )

}