import { useState, useEffect } from "react";

import './ShowNumbers.css';

import { GetListPag, RegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function ModelPage_ShowNumbers(props){

    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));
    const [ showData, setShowData ] = useState(InitialData());

    const [ listBtn, setListBtn ] = useState(ShowListBtn());

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage(props.origin, setDataPage);
    }, []);

    function ShowListBtn(){
        const newData = showData.numbers.filter(item => item.idioma == idioma);
        return newData;
    }

    function InitialData(){
        const newData = dataPage.find(item => item.id == props.id_page);
        return newData;
    }

    return(
        <div className="ModelPage_ShowNumbers">
            <div className="div_number">
                <div className="year">
                    <div className="number_year">{ showData.year.number[idioma] }</div>
                    <div className="name" dangerouslySetInnerHTML={ { __html: showData.year.name[idioma] ? showData.year.name[idioma].replaceAll('&#34;', '"') : "" } } />
                </div>
                <div className="list_number">
                    {
                        listBtn.map((key, index)=>{
                            return(
                                <div className="show_number" key={ index }>
                                    <div className="div_data">
                                        <div className="number sigla">{ key.sigla }</div>
                                        <div className="number">{ key.number }</div>
                                    </div>
                                    <div className="name" dangerouslySetInnerHTML={ { __html: key.name ? key.name.replaceAll('&#34;', '"') : "" } } />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )

}