import { useState, useEffect } from "react";

import './Clients.css';

import { GetListPag, RegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function ModelPage_Clients(props){

    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));
    const [ showData, setShowData ] = useState(InitialData());

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage(props.origin, setDataPage);
    }, []);

    function InitialData(){
        const newData = dataPage.find(item => item.id == props.id_page);
        return newData;
    }

    return(
        <div className="ModelPage_Clients">
            <div className="div_text">
                <div className="show_text">
                    <div className="subtitle">{ showData.subtitle[idioma] }</div>
                    <div className="title" dangerouslySetInnerHTML={ { __html: showData.title[idioma] ? showData.title[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                    {
                        showData.text[idioma] !='' ? 
                        <div className="text" dangerouslySetInnerHTML={ { __html: showData.text[idioma] ? showData.text[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } /> : null
                    }
                </div>
            </div>
            <div className="list_logo">
                {
                    showData.logo.map((key, index)=>{
                        return(
                            <div className="div_img" key={ index }>
                                <img alt={ key.name } src={ key.logo } className="logo" />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}