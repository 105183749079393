import { useState, useEffect } from "react";

import { SetListPag, GetListPag, RegisterListPag } from "interface/Page";

import LineFixed from "components/LineFixed";
import AnimationInitial from "components/AnimationInitial";
import Menu from "components/Menu";
import Page_Home from "components/Pages/Home";
import Page_Footer from "components/Pages/Footer";
import Page_About from "components/Pages/About";
import Page_Works from "components/Pages/Works";
import Page_Contact from "components/Pages/Contact";
import AnimationClickLogo from "components/AnimationClickLogo";
import Page_Preview from "components/Pages/Preview";

export default function App(){

    const [ loading, setLoading ] = useState(false);
    const [ page, setPage ]       = useState(GetListPag('page'));
    const [ pageUrl, setPageUrl ] = useState(window.location.href.split("#"));
    
    const [ statusPage, setStatusPage ] = useState(false);

    useEffect(()=>{
        RegisterListPag('page', setPage);
        CurrentPage(page);
    }, []);

    useEffect(()=>{
        CurrentPage(page);
        setPageUrl(window.location.href.split("#"));
    }, [page]);

    function ReturnTop(){
        window.scrollTo(0, 0);
    }

    function AltPag(value, typeClick){
        // if(typeClick == 'logotipo'){
        //     setStatusPage(true);
        //     setTimeout(() => {
        //         ReturnTop();
        //         setPage(value);
        //         CurrentPage(value);
        //         SetListPag('page', value);

        //         setStatusPage(false);
        //     }, 100000);
        // }else {
        //     ReturnTop();
        //     setPage(value);
        //     CurrentPage(value);
        //     SetListPag('page', value);
        // }
        ReturnTop();
        setPage(value);
        CurrentPage(value);
        SetListPag('page', value);
    }
    
    function CurrentPage(){
        let namePage     = 'home';
        let showNamePage = window.location.href.split("#");
        if(showNamePage[1]){
            switch (showNamePage[1]) {
                case "home": case 'works': case 'about': case 'contact':  case "preview":
                        namePage = showNamePage[1];
                    break;
                default:
                        window.history.pushState("", "", "/");
                        namePage = 'home';
                    break;
            }
        }else {
            namePage = page;
        }

        switch (namePage) {
            case "home":
                return (
                    <>
                        <Page_Home AltPag={ AltPag } />
                        <Page_Footer AltPag={ AltPag } />
                    </>
                );

            case "works":
                return (
                    <>
                        <Page_Works AltPag={ AltPag } />
                        <Page_Footer />
                    </>
                );

            case "about":
                return (
                    <>
                        <Page_About AltPag={ AltPag } />
                        <Page_Footer />
                    </>
                );

            case "contact":
                return (
                    <>
                        <Page_Contact AltPag={ AltPag } setLoading={ setLoading } />
                        <Page_Footer />
                    </>
                );

            case "preview":
                return (
                    <>
                        <Page_Preview idPage={ showNamePage[2] } AltPag={ AltPag } />
                    </>
                );
        
            default:
                return (
                    <>
                        <Page_Home AltPag={ AltPag } />
                        <Page_Footer AltPag={ AltPag } />
                    </>
                );
        }
    }

    return(
        <>
            {/* {
                statusPage == false ? null : <AnimationClickLogo />
            } */}
            <AnimationInitial />
            <LineFixed />
            <Menu AltPag={ AltPag } />
            { CurrentPage() }
        </>
    )
}