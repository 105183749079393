import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import Axios from 'axios';

import { SetListPag }  from 'interface/Page';
import { SetListData } from 'interface/Data';

const root = ReactDOM.createRoot(document.getElementById('root'));

const data = new FormData();
data.append('type_page', 'show_data');

Axios({
    url     : process.env.REACT_APP_API_URL + 'php/list_data.php',
    mode    : 'no-cors',
    method  : 'POST',
    data    : data
})
.then(response => {
    let namePage = window.location.href.split("#");
    if(namePage[1]){
        switch (namePage[1]) {
            case "home": case 'works': case 'about': case 'contact':  case "preview":
                    SetListPag('page', namePage[1]);
                break;
            default:
                    window.history.pushState("", "", "/");
                    SetListPag('page', 'home');
                break;
        }
    }else {
        SetListPag('page', 'home');
    }
    
    console.log(response.data);
    SetListData(response.data);

    root.render(
        <React.Fragment>
            <App />
        </React.Fragment>
    );
});
