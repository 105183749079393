import { useState, useEffect } from "react";

import './Preview.css';

import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function Page_Preview(props){
    
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('portfolio'));
    const [ showData, setShowData ] = useState(InitialData());

    const [ listDataPage, setListDataPage ] = useState(ShowDataIdioma());

    useEffect(()=>{        
        RegisterListPag("idioma", setIdioma);
        RegisterDataPage("portfolio", setDataPage);

        document.title = 'Projeto ' + showData.title;
    }, []);

    function InitialData(){
        let showNamePage = window.location.href.split("#");
        if(showNamePage[1]){
            const newData = dataPage.portfolio.find(item => item.id_portfolio == showNamePage[2]);
            return newData;

        }else {
            window.history.pushState("", "", "/");
            props.AltPag('index');
        }
    }

    function ShowDataIdioma(){
        const newData = showData.data.find(item => item.idioma == idioma);
        return newData;
    }
    
    function ShowVideo(type, video){
        switch (type) {
            case 'Vimeo':
                return <iframe className="div_video" src={ "https://player.vimeo.com/video/" + video + "" } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" />;

            case 'Drive':
                let linkDrive = video.split('/');
                return <iframe className="div_video" src={ "https://drive.google.com/file/d/" + linkDrive[5] + "/preview" } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" />;    
            
            case "Youtube":
                return <iframe className="div_video" src={ "https://www.youtube.com/embed/" + video + "" } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" />;
        }
    }

    function OpenFile(value){
        SetModalData('ShowFile', { "file": value, "color": showData.bg_color });
        SetModalState('ShowFile', true);
    }

    console.log(listDataPage);

    return(
        <div className="Preview_Portfolio" style={ { backgroundColor: showData.color } }>
            
            <div className="div_initial">
                <img alt="img" src={ listDataPage.file } className="img_full" />
            </div>
            <div className="div_line">
                <div className="line bg_1" />
                <div className="line bg_2" />
                <div className="line bg_3" />
                <div className="line bg_4" />
            </div>

            <div className="div_data_project contents">
                <div className="div_data">
                    <div className="data_project">
                        <div className="title" dangerouslySetInnerHTML={ { __html: listDataPage.project ? listDataPage.project.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                        {
                            listDataPage.subtitle !='' ? 
                            <div className="subtitle" dangerouslySetInnerHTML={ { __html: listDataPage.subtitle ? listDataPage.subtitle.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } /> : null
                        }
                    </div>
                    <div className="data_project">
                        <div className="name_cliente">CLIENT: { showData.client }</div>
                        {
                            showData.year !='' ? <div className="name_cliente">YEAR: { showData.year }</div> : null
                        }                        
                        {
                            listDataPage.type !='' ? 
                            <div className="name_cliente">TYPE: { listDataPage.type }</div> : null
                        }                        
                    </div>
                </div>
                <div className="div_data align_top">
                    <div className="data_project text_project" dangerouslySetInnerHTML={ { __html: listDataPage.text ? listDataPage.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                </div>
            </div> 
           
            {
                listDataPage.contents.map((key, index)=>{
                    return(
                        <div className={ key.page_full == true ? "show_data_page page_full" : "show_data_page" } key={ index }>
                            {
                                key.data_contents.map((key_1, index_1)=>{
                                    switch (key_1.type) {
                                        case 'img':
                                            return(
                                                <div className="div_contents" key={ index_1 }>
                                                    <img alt={ key_1.name } src={ key_1.file } className="show_img" />
                                                </div>
                                            )

                                        case 'text':
                                            return(
                                                <div className="div_contents" key={ index_1 }>
                                                    <div className="div_text">
                                                        <div className="title">{ key_1.title }</div>
                                                        <div className="text" dangerouslySetInnerHTML={ { __html: key_1.text ? key_1.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                                    </div>
                                                </div>
                                            )

                                        case 'video':
                                            return(
                                                <div className="div_contents" key={ index_1 }>
                                                    { ShowVideo(key_1.type_video, key_1.link) }
                                                </div>
                                            )
                                    }
                                })
                            }
                        </div>
                    )
                })
            }

            {
                listDataPage.datasheet ? 
                <div className="datasheet" style={ { padding: "60px 0px" } }>
                    <div className="data_project text_project" dangerouslySetInnerHTML={ { __html: listDataPage .datasheet ? listDataPage.datasheet.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                </div> : null
            }
        </div>
    )
}