import { useState, useEffect } from "react";

import './Works.css';

import { GetListPag, RegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import ModelPage_Home        from "components/Model/Home";
import ModelPage_Text        from "components/Model/Text";
import ModelPage_Video       from "components/Model/Video";
import ModelPage_Clients     from "components/Model/Clients";
import ModelPage_Spotlight   from "components/Model/Spotlight";
import ModelPage_Portfolio   from "components/Model/Portfolio";
import ModelPage_ShowNumbers from "components/Model/ShowNumbers";
import ModelPage_OurServices from "components/Model/OurServices";

export default function Page_Works(props){

    const [ page, setPage ]         = useState(GetListPag('page'));
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('works'));

    useEffect(()=>{
        RegisterListPag('page', setPage);
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage('works', setDataPage);
    }, []);

    function CurrentPage(value, id){
        switch (value) {
            case "home":
                return <ModelPage_Home origin='works' id_page={ id } AltPag={ props.AltPag } />;

            case "our_services":
                return <ModelPage_OurServices origin='works' id_page={ id } AltPag={ props.AltPag } />;

            case "spotlight": 
                return <ModelPage_Spotlight origin='works' id_page={ id } AltPag={ props.AltPag } />;

            case "portfolio":
                return <ModelPage_Portfolio origin='works' id_page={ id } AltPag={ props.AltPag } />;

            case "text":
                return <ModelPage_Text origin='works' id_page={ id } AltPag={ props.AltPag } />;

            case "show_numbers":
                return<ModelPage_ShowNumbers origin='works' id_page={ id } AltPag={ props.AltPag } />;

            case "video":
                return <ModelPage_Video origin='works' id_page={ id } AltPag={ props.AltPag } />;

            case "clients": 
                return <ModelPage_Clients origin='works' id_page={ id } AltPag={ props.AltPag } />;
        }
    }
    
    return(
        <div className="Page_Works">
            {                
                dataPage.map((key, index)=>{
                    let classNameAdd = '';
                    if(index == 0){
                        classNameAdd = 'space_top';
                    }
                    return (
                        <div className="section" key={ index } style={ { backgroundColor: key.color_page[idioma] } }>
                            { CurrentPage(key.type_page, key.id) }
                        </div>
                    )
                })
            }
            <div className="section">
                <ModelPage_Portfolio origin='portfolio' AltPag={ props.AltPag } />
            </div>
        </div>
    )
}