import { useState, useEffect } from "react";

import './Contact.css';

import TextareaAutosize from 'react-textarea-autosize';

import { Reg_Contact } from "services";

import { GetListPag, RegisterListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function Page_Contact(props){

    const [ page, setPage ]         = useState(GetListPag('page'));
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('contact'));

    const [ name, setName ]       = useState('');
    const [ email, setEmail ]     = useState('');
    const [ subject, setSubject ] = useState('');
    const [ text, setText ]       = useState('');

    const [ textFixed, setTextFixed ] = useState([
        {
            "en": "Name...",
            "es": "Nombre...",
            "pt_br": "Nome..."
        },
        {
            "en": "Email...",
            "es": "Correo electrónico...",
            "pt_br": "E-mail..."
        },
        {
            "en": "Subject...",
            "es": "Sujeto...",
            "pt_br": "Assunto..."
        },
        {
            "en": "Message...",
            "es": "Mensaje...",
            "pt_br": "Mensagem..."
        },
        {
            "en": "To save",
            "es": "Ahorrar",
            "pt_br": "Salvar"
        }
    ]);

    useEffect(()=>{
        RegisterListPag('page', setPage);
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage('contact', setDataPage);
    }, []);

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Contact(name, email, subject, text, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        setName('');
        setEmail('');
        setSubject('');
        setText('');
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }
    console.log(dataPage);

    return(
        <div className="section Page_Contact">
            <div className="div_text">
                <div className="show_text">
                    <div className="subtitle">{ dataPage[0].subtitle[idioma] }</div>
                    <div className="title" dangerouslySetInnerHTML={ { __html: dataPage[0].title[idioma] ? dataPage[0].title[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                    <div className="text" dangerouslySetInnerHTML={ { __html: dataPage[0].text[idioma] ? dataPage[0].text[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                </div>
            </div>

            <div className="list_form">
                <form onSubmit={ SaveData }>
                    <div className="div_form">
                        <div className="div_input input_text_">
                            <input type="text" className="" onChange={ (e)=>{ setName(e.target.value) } } placeholder={ textFixed[0][idioma] } value={ name } required />
                        </div>
                        <div className="div_input input_text_">
                            <input type="email" className="" onChange={ (e)=>{ setEmail(e.target.value) } } placeholder={ textFixed[1][idioma] } value={ email } required />
                        </div>
                        <div className="div_input">
                            <input type="text" className="" onChange={ (e)=>{ setSubject(e.target.value) } } placeholder={ textFixed[2][idioma] } value={ subject } required />
                        </div>
                        <div className="div_input">
                            <TextareaAutosize className="textarea" onChange={ (e)=>{ setText(e.target.value) } } placeholder={ textFixed[3][idioma] } value={ text } required />
                        </div>
                        <div className="div_input">
                            <button className="btn_save">
                                { textFixed[4][idioma] }
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}