import { useState, useEffect } from "react";

import './Spotlight.css';

import { GetListPag, RegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgSetaRight } from "components/SvgFile";

export default function ModelPage_Spotlight(props){

    const [ page, setPage ]         = useState(GetListPag('page'));
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));
    const [ showData, setShowData ] = useState(InitialData());

    const [ listBtn, setListBtn ] = useState(ShowDataIdioma());

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('page', setPage);

        NextImg();
    }, []);

    function InitialData(){
        const newData = dataPage.find(item => item.id == props.id_page);
        return newData;
    }

    function ShowDataIdioma(){
        const dataPortfolio = [];
        const newData = showData.list_btn.filter(item => item.idioma == idioma);
        switch (newData.length) {
            case 1:
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                break;
            case 2:
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                break;
            case 3:
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                break;
            case 4:
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                break;
            default:
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                    newData.map((key, index)=>{
                        dataPortfolio.push(key);
                    })
                break
        }
        return dataPortfolio;
    }
    
    function AltPosition_prev(){
        var stream = document.querySelector('.div_carousel');
        var items = document.querySelectorAll('.gallery__item');

        var prev = document.querySelector('.gallery__prev');
        
        stream.insertBefore(items[items.length - 1], items[0]);
        items = document.querySelectorAll('.gallery__item');
    }
    
    function AltPosition_next(){
        var stream = document.querySelector('.div_carousel');
        var items = document.querySelectorAll('.gallery__item');

        var next = document.querySelector('.gallery__next');
        
        stream.appendChild(items[0]);
        items = document.querySelectorAll('.gallery__item');
    }

    function NextImg(){
        setInterval(()=>{
            // var stream = document.querySelector('.div_carousel');
            // var items  = document.querySelectorAll('.gallery__item');

            // var next   = document.querySelector('.gallery__next');
            // next.click(function () {
            //     stream.appendChild(items[0]);
            //     items = document.querySelectorAll('.gallery__item');
            // });           
        }, 7000);
    }

    return(
        <div className="ModelPage_Spotlight">
            <div className="show_title">
                <div className="div_title">
                    <div className="title_page">PORTFÓLIO</div>
                    <div className="subtitle_page">SPOTLIGHT</div>
                </div>
            </div>

            <div className="show_spotlight">
                <div className="gallery__prev" onClick={ ()=>{ AltPosition_prev() } }>
                    <div className="icons_carousel_prev">
                        <SvgSetaRight color="#ffffff" className="icons_galerry icons_galerry_next" />
                    </div>
                </div>
                <div className="gallery__next" onClick={ ()=>{ AltPosition_next() } }>
                    <div className="icons_carousel_next">
                        <SvgSetaRight color="#ffffff" className="icons_galerry" />
                    </div>
                </div>

                <div className="div_carousel">
                    {
                        listBtn.map((key, index)=>{ 
                            return (    
                                <div className="gallery__item" key={ index }>
                                    <div className="show_gallery_data">
                                        <div className="div_img">
                                            <div className="div_name_img">
                                                <div className="name_project" data-text={ key.title }>
                                                    { key.title }
                                                </div>
                                            </div>
                                            <div className="btn_gallery">
                                                <div className="title_btn" dangerouslySetInnerHTML={ { __html: key.subtitle ? key.subtitle.replaceAll('&#34;', '"') : "" } } />
                                                <a href={ "/#preview#" + key.id_portfolio } onClick={ ()=>{ props.AltPag('preview') } } className="show_btn">
                                                    Ver
                                                </a>
                                            </div>
                                            <div className="div_show_color">
                                                <div className="div_color_clip" />
                                                <div className="div_color_blur" id={ "blur_" + index } />
                                            </div>
                                            <img alt={ key.title } src={ key.file } className="img_example" />
                                        </div>
                                    </div>
                                </div> 
                            )
                        })
                    } 
                </div>
            </div>

        </div>
    )

}