import { useState, useEffect } from "react";

import './Video.css';

import { GetListPag, RegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function ModelPage_Video(props){

    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));
    const [ showData, setShowData ] = useState(InitialData());

    const opts = { 
        playerVars: {
            autoplay: true,
            controls: false,
            rel: false,
            showinfo: false,
            mute: true,
            loop: true
        } 
    };

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterDataPage(props.origin, setDataPage);
    }, []);

    function InitialData(){
        const newData = dataPage.find(item => item.id == props.id_page);
        return newData;
    }
    
    function ShowVideo(type, video){
        switch (type) {
            case 'Vimeo':
                return <iframe className="div_video" src={ "https://player.vimeo.com/video/" + video + "" } frameBorder="0" allow="fullscreen; picture-in-picture" />;

            case 'Drive':
                let linkDrive = video.split('/');
                return <iframe className="div_video" src={ "https://drive.google.com/file/d/" + linkDrive[5] + "/preview" } frameBorder="0" allow="fullscreen; picture-in-picture" />;    
            
            default:
                return <iframe className="div_video" src={ "https://www.youtube.com/embed/" + video } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" />;
        }
    }

    return(
        <div className="ModelPage_Video">
            <div className="div_text div_right">
                <div className="show_text">
                    {
                        showData.subtitle[idioma] !='' ? 
                        <div className="subtitle">{ showData.subtitle[idioma] }</div> : null
                    }
                    {
                        showData.title[idioma] !='' ?
                        <div className="title" dangerouslySetInnerHTML={ { __html: showData.title[idioma] ? showData.title[idioma].replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } /> : null
                    }
                </div>
            </div>
            <div className="list_video">
                <div className="show_video">
                    { ShowVideo(showData.type[idioma], showData.video[idioma]) }
                </div>
            </div>
        </div>
    )

}