import Axios from 'axios';

export function Reg_Contact(name, email, subject, text, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', 'contact');
    data.append('name', name);
    data.append('email', email);
    data.append('subject', subject);
    data.append('text', text);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        if(response.data == 'ok!'){
            CallbackSuccess();

        }else {
            CallbackError();
        }

    }).catch((error)=>{

        CallbackError();
    })
}